import httpUtil from "@/utils/httpUtil";

/**获取图片文件上传图片列表 */
export function fetchListCustom(params) {
	return httpUtil.get("/file/listCustom", params)
}

/***保存模版 **/
export function fetchSaveTemplate(params) {
	return httpUtil.post("/customPage", params)
}

/***根据模版id获取页面 **/
export function fetchGetTemplate(id) {
	return httpUtil.get('/customPage/model/'+id)
}

/***编辑模版**/
export function fetchUpdateTemplate(params) {
	return httpUtil.put("/customPage", params)
}

/** 获取模版列表 **/
export function fetchCustomPage(params) {
	// return httpUtil.get("/customPage", params)
	return httpUtil.get("/customPage/crmModel", params)
}

/***保存页面 **/
export function fetchSavePage(params) {
	return httpUtil.post("/customPage/page", params)
}

/***编辑页面 */
export function fetchUpdatePage(params) {
	return httpUtil.put("/customPage/page", params)
}

/**根据页面id获取页面 */
export function fetchPageData(pageId) {
	return httpUtil.get(`/customPage/page/${pageId}`)
}

/***生成页面列表**/
export function fetchCreatePageList(params) {
	return httpUtil.get("/customPage/page", params)
}

/***生成标识**/
export function fetchCreateHandle(params) {
	return httpUtil.post("/customPage/pageHandle", params)
}

/***标识页面列表 */
export function fetchHandlePageList(params) {
	return httpUtil.get("/customPage/pageHandle", params)
}
/**验证记录 */
export function fetchVerPageList(params) {
	return httpUtil.get("/customPage/verification/list", params)
}

/**删除模版*/
export function fetchDeletePage(id) {
	return httpUtil.delete(`/customPage/${id}`)
}

/*** 获取商品列表接口 */
export function fetchProductList(params) {
	return httpUtil.post("/goods/getGoodsListPc", params)
}

/**生成标识 ，根据类型获取可使用次数 */
export function fetchGetCustomCountByType(params) {
	return httpUtil.get("/customPage/getCustomCountByType", params)
}

/**修改可扫码次数**/
export function fetchUpdateScanNum(params) {
	return httpUtil.post("/customPage/updateScanNum", params)
}

/**获取成员名片列表***/
export function fetchMembertList(params) {
	return httpUtil.post("/businessCard/getMemberList", params)
}

/***获取上传视频列表 */
export function fetchVideoList(params) {
	return httpUtil.get("/company/getCompanyVideos", params)
}

/****下单打印*/
export function fetchPrint(params) {
	return httpUtil.post("/print/order", params)
}

/***删除自定义页面 ***/
export function fetchDeleteCreatePage(params) {
	return httpUtil.put("/customPage/page", params)
}

/***获取部门 */
export function fetchDepartment(params) {
	return httpUtil.get("/department/queryDepart", params)
}

/***绑定部门 绑定内部页*/
export function fetchCheckDepartment(params) {
	return httpUtil.post("/customPage/bindingDept", params)
}


/***导出标识 excel */
export function fetcExportHandle(params) {
	return httpUtil.get("/customPage/export", params)
}

/***导出标识 txt格式 */
export function fetcExportHandleTxt(params) {
	return httpUtil.get("/customPage/exportNoBindTxt", params)
}



/****数据模板列表****/

export function fetchDataModelList(params) {

	return httpUtil.get("/dataModel/list", params)
}

/****添加数据模板****/

export function fetchAddDataModel(params) {

	return httpUtil.post("/dataModel", params)
}

/****数据模板id获取详情 ****/

export function fetchDataModelInfo(params) {
	return httpUtil.get("/dataModel/info", params)
}

/***数据模板删除 ****/
export function fetchDelDataModel(params) {
	return httpUtil.post("/dataModel/delete", params)
}



/****标识模板列表****/

export function fetchIdenModelList(params) {

	return httpUtil.get("/idenModel/list", params)
}

/****添加标识模板****/

export function fetchAddIdenModell(params) {

	return httpUtil.post("/idenModel", params)
}

// /****标识模板id获取详情 ****/

export function fetchIdenModelInfo(params) {
	return httpUtil.get("/idenModel/info", params)
}

/***标识模板删除 ****/
export function fetchDelIdenModel(params) {
	return httpUtil.post("/idenModel/idenModelDel", params)
}

/**页面积分记录 */
export function fetchPageVipRecord(params) {
  return httpUtil.get("/integralRecord/pcList", params)
}

/** 获取绑定经销商列表-不分页 */
export function fetchDealerAllList(params) {
  return httpUtil.get("/dealer/noPage", params)
}

/***页面列表不分页 */
export function fetchPagesAllList(params) {
  return httpUtil.get("/customPage/noPageList", params)
}

/** 获取绑定的标识*/
export function fetchBindProList(params) {
	return httpUtil.get("/customPage/pageHandle", params)
}
// 导出关联标识
export function fetchExportBind(params) {
	return httpUtil.get("/customPage/exportTxt", params)
}

// 删除素材库图片
export function fetchDelImg(params) {
	return httpUtil.delete(`/file/deleteFile/${params}`)
}

// 获取注册进度
export function fetchNowPercent(params) {
  return httpUtil.get("/customPage/regSpeed", params)
}

//不分页客户
export function customerAllList(params) {
	return httpUtil.get("/handleCustomer/noPage", params)
}

// 单个标识扫码记录
export function fetchOneScanLog(params) {
	return httpUtil.get("/customPage/scanLog/list", params)
}

// 修改销售地区
export function fetchUpdateArea(params) {
	return httpUtil.post("/customPage/bindArea", params)
}

// 启用/禁用标识
export function fetchDisableHandle(params) {
	return httpUtil.post("/customPage/disableHandle", params)
}
export function fetchBindPageBatchList(params) {
	return httpUtil.get("/customPage/getBindLotNumber", params)
}

export function fetchprintHandleList(params) {
	return httpUtil.get("/customPage/printHandleList", params)
}

// 版本更新，获取版本更新列表
export function fetchVerData(params) {
	return httpUtil.get("/crmVersion", params)
}

// 版本更新，添加版本

export function fetchAddVer(params) {
	return httpUtil.post("/crmVersion", params)
}


// 窖藏酒接口
export function fetchWineInfo(params) {
	return httpUtil.post("/iot/jcj/del", params)
}